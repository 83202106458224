import Rails from '@rails/ujs';
import Swal from 'sweetalert2';
window.Swal = Swal;

// This is the native confirm, showing a browser alert/confirm dialog
const nativeConfirm = Rails.confirm;

let __SkipConfirmation = false;

Rails.confirm = function (message, element) {
  // JavaScript is single threaded. We can temporarily change this variable
  // in order to skip out of the confirmation logic.
  //
  // When this function returns true, the event (such as a click event) that
  // sourced it is not prevented from executing whatever it was supposed to
  // trigger, such as a form submission, or following a link.
  if (__SkipConfirmation) {
    return true;
  }

  // Here is the logic to determine if a custom dialog should be shown. In
  // this case, we'd expect [data-confirm-dialog="id"] to be present, but
  // other techniques, such as dynamically building a dialog from the
  // [data-confirm] content would also work.
  // const dialogId = element.getAttribute('data-confirm-dialog');
  // if (!dialogId) {
  //   return nativeConfirm(message, element);
  // }

  if (!element.hasAttribute('data-sweet-alert')) {
    return nativeConfirm(message, element);
  }

  // This function should be executed when the dialog's positive action is
  // clicked. All it does is re-click the element that was originally
  // triggering this confirmation.
  //
  // Clicking that element will, as expected, re-call Rails.confirm (unless
  // we'd remove [data-confirm] temporarily, which is the alternative solution
  // to this), but because __SkipConfirmation is set, it will bail out early.
  function onConfirm() {
    __SkipConfirmation = true
    element.click()
    __SkipConfirmation = false
  }

  // Here a custom dialog can be shown. use whatever method you like. This
  // hypothetical function shows a dialog.
  //
  showDialog(element, onConfirm)

  // The dialog should, on confirm, call onConfirm()

  // This ensures that the original event that caused this confirmation is
  // swallowed and the action is NOT executed.
  return false;
};

function showDialog(element, onConfirm) {
  
  const options = JSON.parse(element.getAttribute('data-sweet-alert') || '{}')
  const message = element.getAttribute('data-confirm')
  debugger
  Swal.fire({
    title: 'Are you sure?',
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    ...options
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm()
    }
  })
}
